<template>
    <Header></Header>
    <div style=" background-color:#fff; padding:20px;margin:20px;border-radius: 2px;position:relative;">
        <div class="search-title">
            <span>优企查询</span>
        </div>
        <el-row :gutter="10">
        <el-col  class="col" :xs="12" :sm="8" :md="8" :lg="4" :xl="4" v-for="item in labelList" :key="item.labelId">
            <el-card shadow="always" :body-style="{padding:'10px',background:selCard==item.labelId?'rgba(73, 146, 255, 0.1)':'transparent'}" @click="queryWhereFun('labelIds',item)">
                <div class="card">
                    <div class="card-c" >
                        <strong class="number-text">{{item.epNum}}</strong><span>&nbsp;家</span>
                        <br>
                        <span class="txt-color">{{item.labelName}}</span> 
                    </div>
                    <div class="card-p">
                        <div class="pie">
                            <div v-if="parseInt(4*item.pct)>=1" class="slice-1 slice ">
                            </div>
                            <div v-if="parseInt(4*item.pct)>=2" class="slice-2 slice ">
                            </div>
                            <div v-if="parseInt(4*item.pct)>=3" class="slice-3 slice ">
                            </div>
                            <div  class="slice-1 slice " :style="{transform: 'rotate('+parseInt(4*item.pct)*90+'deg) skewY('+((360*item.pct%90)-90)+'deg)'}">
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
        </el-col>
    </el-row>
    <el-collapse style="border:1px">
        <el-collapse-item title="筛选条件" name="1" class="collapse-cus">
            <div v-if="Object.keys(enterpriseQuery).length>0?true:false">
                <span class="search-t">已选条件：</span> 
                <div class="search-content">
                    <el-tag
                        :key="tag"
                        v-for="(tag,key) in enterpriseQuery"
                        closable
                        :disable-transitions="false"
                        class="tag-cus"
                        size='small'
                        @close="handleCloseFun(tag,key)">
                        {{tag.name}}
                        </el-tag>
                </div>
            </div>
            <div>
                <span class="search-t">关键词：</span> 
                <div class="search-content">
                    <el-input
                    placeholder="企业名称、统一社会信用代码"
                    prefix-icon="el-icon-search"
                    @keyup.enter="queryWhereFun('search','')"
                    v-model="iptSearch">
                    </el-input>
                </div>
            </div>
            <div v-if="enterpriseQuery.hasOwnProperty('registeredCapital')?false:true">
                <span class="search-t">注册资本：</span> 
                <div class="search-content">                                                                                                                                                                                    
                    <ul>
                        <li v-for="item in regCapitalList" :key="item.code" @click="queryWhereFun('registeredCapital',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if="enterpriseQuery.hasOwnProperty('createDate')?false:true">
                <span class="search-t">成立日期：</span> 
                <div class="search-content">
                    <ul>
                        <li v-for="item in estiblishTimeList" :key="item.code" @click="queryWhereFun('createDate',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            
            <div v-if="enterpriseQuery.hasOwnProperty('epStyle')?false:true">
                <span class="search-t">企业类型：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in enterpriseType" :key="item.code" @click="queryWhereFun('epStyle',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            
            <div v-if="enterpriseQuery.hasOwnProperty('insuredPerson')?false:true">
                <span class="search-t">参保人数：</span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in insuredPersonList" :key="item.code" @click="queryWhereFun('insuredPerson',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if="regionList==null?false:true">
                <span class="search-t">
                    地区：
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in regionList" :key="item.code"  @click="queryWhereFun('region',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if="econoIndustryClass==null?false:true">
                <span class="search-t">
                    行业分类：
                </span>
                <div class="search-content">
                    <ul>
                        <li v-for="item in econoIndustryClass" :key="item.code"  @click="queryWhereFun('econoIndustry',item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div v-if="enterpriseQuery.hasOwnProperty('labelIds')?false:true">
                <span class="search-t">
                    标签分类：
                </span>
                <div class="search-content">
                <ul>
                    <li v-for="item in labelList" :key="item.labelId"  @click="queryWhereFun('labelIds',item)">{{item.labelName}}</li>
                </ul>
            </div>
            </div>
        </el-collapse-item>
    </el-collapse>
    </div>
    <div style="margin:20px;border-radius:2px;border: 1px solid #DCDFE6;background-color: #fff;">
        <div class="result-tips clearfix">
            <span>找到 <span>{{total}}</span> 条相关结果</span>
            <!-- <el-button type="primary" @click='download' size="small" round>导出数据</el-button> -->
        </div>
        <div>
            <ul class="result-list">
                <li v-for="o in enterpriseList" :key="o">
                    <el-image :src="o.logoUrl" class="ep-logo" style="margin-left:10px;">
                        <template #error>
                            <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                    <div class='result-list-content' >
                        <el-link :underline="false" :href="`/#/ep/epAnalysis/`+o.id" 
                        target="_blank"  class="ep-link">{{o.name}}</el-link>
                        <div>
                            <el-tag  v-for="label in o.labelList" :key="label"  class="tag-cus-label" size='small'>{{label}}</el-tag>
                        </div>
                        <div style="color:#666">
                            <span>注册资本：{{o.registeredcapital}}万{{o.capitalTypeName}} </span>
                            <span>&nbsp;&nbsp;成立日期：{{parseTime( new Date(o.createdate),'{y}-{m}-{d}')}}</span>
                            <span>&nbsp;&nbsp;法人：{{o.legalperson}}</span>
                            <span>&nbsp;&nbsp;企业类型：{{o.epstyle}}</span>
                            <br>
                            <span>地址：{{o.address}}</span>
                        </div>
                        <span class="site">
                            {{o.provinceName}}
                        </span>
                    </div>
                
                </li>
            </ul>
            <el-pagination
               class="page-cus"
                @current-change="handleCurrentChangeFun"
                :current-page="currentPage"
                :hide-on-single-page="total<pageSize?true:false"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header'
import { reactive, ref } from '@vue/reactivity'
//import {nextTick}from 'vue'
import {getEpStyle,getEpList,getLabelList,getEpNum,getLabelListOfEp,exportEpList} from '@/http/epApi.js'
import {getNextCodeNicList,getCodeRegionList} from '@/http/basicsApi.js'

import { onMounted } from '@vue/runtime-core'
import { ElLoading } from 'element-plus';
import {parseTime} from '@/utils/index.js'
//import { useRoute } from 'vue-router'
import axios from 'axios'
export default {
    name:'EpList',
    components: {
        Header
    },
    setup() {
        //const route=useRoute();
        let currentPage=ref(1);//当前页
        let pageSize=ref(10);//每页显示的行数
        let total=ref(0);//总行数
        //已筛选条件
        let enterpriseQuery=reactive({
        })
        //关键词：企业名称、统一社会信用代码
        let iptSearch=ref('');//关键词
        //注册资本
        const regCapitalList=reactive([
            {code:"0,100",name:"0~100万"},
            {code:"100,200",name:"100~200万"},
            {code:"200,500",name:"200~500万"},
            {code:"500,1000",name:"500~1000万"},
            {code:"1000",name:"1000万以上"}
        ])
        //成立日期,code的第一个元素表示年，后面两位表示时间间隔
        const estiblishTimeList=reactive([
            {code:"0,1",name:"成立1年内"},
            {code:"1,5",name:"成立1～5年"},
            {code:"5,10",name:"成立5～10年"},
            {code:"10,15",name:"成立10～15年"},
            {code:"15",name:"成立15年以上"}
        ])
        //获取企业类型
        let enterpriseType=ref(null);//企业类型
        const getEnterpriseTypeFun= async ()=>{
            let result=await getEpStyle();
            if(result.code==20000){
                enterpriseType.value=result.data;
            }
            console.log(enterpriseType.value);
        }

        //参保人数
        const insuredPersonList=reactive([
            {code:"0,100",name:"小于100人"},
            {code:"100,499",name:"100-499人"},
            {code:"500,999",name:"500-999人"},
            {code:"1000,4999",name:"1000-4999人"},
             {code:"5000,9999",name:"5000-9999人"},
              {code:"10000",name:"10000人以上"}
        ])
        let selCard=ref(0);
        let labelList=ref(null);
        //获取标签数据信息(标签企业的数量，占企业的比重)
        const getLabelInfoFun= async ()=>{

            axios.all([getEpNum({state:1}), getLabelList({ids:'1,2,3,4,5,6',pageIndex:1,pageSize:1})])
            .then(axios.spread(function (resp1, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
                // 两个请求现在都执行完成
                console.log(resp1);
                console.log(resp2);
                let epNum=0;
                if(resp1.code==20000){
                        epNum=resp1.data;
                }
                if(resp2.code==20000){
                    labelList.value=resp2.data.dataList.map((el)=>{
                        return {labelId:el.labelId,
                        labelName:el.labelName,
                        epNum:el.epNum,
                        pct:epNum!=0? el.epNum/epNum :null}
                    })
                }
            }));
        }
        let econoIndustryClass=ref(null);//行业分类
        //获取行业分类
        const getEconoIndustryClassFun= async (item)=>{
            console.log(item);
            if(parseInt(item.level)>=3){
                econoIndustryClass.value=null;
                return;
            }
            //获取下级行业分类
            let result=  await getNextCodeNicList(item.code);
            console.log(result);
            if(result.code==20000){
              econoIndustryClass.value=result.data;
            }
        }
        let regionList=ref(null);//地区列表
        //获取地区列表
        const getRegionListFun= async (item)=>{
            console.log(item);
            if(parseInt(item.level)>=2 || ',110000,120000,310000,500000,'.indexOf(','+item.code+',')>-1){
                regionList.value=null;
                return;
            }
            let params={
                parent:item.code
            }
            //获取下级地区列表
            let result=  await getCodeRegionList(params);
            console.log(result);
            if(result.code==20000){
              regionList.value=result.data;
            }
        }
        
        
        //获取查询条件
        const queryWhereFun=(key,item)=>{
            currentPage.value=1;
            console.log(item);
            //关键字查询
            if(key=="search"){
                if(iptSearch.value){
                    enterpriseQuery["search"]={name:iptSearch.value};
                }
                else{
                    delete enterpriseQuery["search"];
                }
            }
            else if(key=="labelIds"){
                selCard.value=item.labelId;
                enterpriseQuery[key]={code:item.labelId,name:item.labelName};
            }
            //地区列表
            else if(key=="region"){
                if(item.level==1){
                    enterpriseQuery["province"]={code:item.code,name:item.name,level:item.level};
                }
                else
                {
                    enterpriseQuery["city"]={code:item.code,name:item.name,level:item.level};
                }
                getRegionListFun(item);
            }
            //行业分类
            else if(key=="econoIndustry"){
                if(item.level==1){
                    enterpriseQuery["industryCodeA"]=item;
                }
                else if(item.level==2)
                {
                    enterpriseQuery["industryCodeB"]=item;
                }
                else
                {
                    enterpriseQuery["industryCode"]=item;
                }
                getEconoIndustryClassFun(item);
            }
            else{
                enterpriseQuery[key]=item;
            }
            //企业查询
            getEnterpreiseListFun();
        }
        //已选条件的删除
        const handleCloseFun=(item,key)=>{
             currentPage.value=1;
            if(key=="industryCodeA"){
                delete enterpriseQuery["industryCodeA"];
                delete enterpriseQuery["industryCodeB"];
                delete enterpriseQuery["industryCode"];
            }
            else if(key=='industryCodeB'){
                delete enterpriseQuery["industryCodeB"];
                delete enterpriseQuery["industryCode"];
            }
            else if(key=='industryCode'){
                delete enterpriseQuery["industryCode"];
            }
            
            else if(key=="labelIds"){
                selCard.value=0;
            }
            else if(key=="provice"){
                delete enterpriseQuery["provice"];
                delete enterpriseQuery["city"];
                getRegionListFun({code:'0',level:0});
            }
            else if(key=="city"){
                delete enterpriseQuery["city"];
                getRegionListFun(enterpriseQuery["provice"]);
            }
            if(key.indexOf("industryCode")>-1){
              getEconoIndustryClassFun({code:item.parent});
            }
            delete enterpriseQuery[key];

            //企业查询
            getEnterpreiseListFun();
        }
        //获取标签的数据信息
        getLabelInfoFun();
        //获取企业类型
        getEnterpriseTypeFun();
        //获取行业门类
        getEconoIndustryClassFun({code:''});
        //获取省级地区
        getRegionListFun({code:'0',level:0});
       
        let enterpriseList=ref(null);//企业列表
        //获取企业列表,分页
        const getEnterpreiseListFun= async()=>{
            
            console.log(enterpriseQuery)
            let params={pageIndex:currentPage.value,pageSize:pageSize.value,status:'1'};
            let keyList='';
            for(let key in enterpriseQuery){
                keyList=keyList+','+key;
                console.log(keyList);
                if(key=='search'){
                    params[key]=enterpriseQuery[key].name;
                }else if(key=='epStyle'){
                    params['epStyle']=enterpriseQuery[key].id;
                }else if(key=='industryCodeB' && (keyList+',').indexOf(',industryCode,')<0){
                    params['industryCode']=enterpriseQuery[key].code.toString();
                }
                else{
                    params[key]=enterpriseQuery[key].code.toString();
                }
            }
            console.log(params);
            let result=await getEpList(params);
            console.log(result);
            if(result.code==20000){
                total.value=result.data.totalCount;
                enterpriseList.value=result.data.dataList;
                //console.log(enterpriseList.value);
                //循环企业，获取企业的标签列表
                enterpriseList.value.forEach(el => {
                    params={
                        epId:el.id,
                        isShow:true,
                        pageIndex:1,
                        pageSize:10
                    }
                    getLabelListOfEp(params).then(info => {
                    if(info.code==20000){
                        //console.log(info);
                        el['labelList']=info.data.dataList.map(label=>{
                            return label.labelName;
                        });
                        el['labelNum']=info.data.totalCount;
                    }
                    });
                })
                console.log(enterpriseList.value);
            }
            
        }
        //单击分页
        const handleCurrentChangeFun=(current)=>{
            currentPage.value = current;
            //企业查询
            getEnterpreiseListFun();
        }
        //数据导出，只能导出前1000条数据，需要更多的数据请联系管理员
        const download=()=>{
            const _loading = ElLoading.service({
                lock: true,
                text: total.value>1000? '导出前1000条数据，更的数据请联系管理员，数据导出中，请稍后...':'数据导出中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const params={pageIndex:1,pageSize:1000,status:'1'};
            let keyList='';
            for(let key in enterpriseQuery){
                keyList=keyList+','+key;
                console.log(keyList);
                if(key=='search'){
                    params[key]=enterpriseQuery[key].name;
                }else if(key=='epStyle'){
                    params['epStyle']=enterpriseQuery[key].id;
                }else if(key=='industryCodeB' && (keyList+',').indexOf(',industryCode,')<0){
                    params['industryCode']=enterpriseQuery[key].code.toString();
                }
                else{
                    params[key]=enterpriseQuery[key].code.toString();
                }
            }
            console.log(params);
            // for(let key in enterpriseWhere){
            //     //console.log(key);
            //     if(key=='search'){
            //         params[key]=enterpriseWhere[key].name;
            //     }else if(key=='regCapitalList' || key=="estiblishTimeList" || key=="insuredPersonList"){//注册资本,成立日期，参保人数
            //          params[key]=[enterpriseWhere[key].code];
            //     }else if(key=='labelCodes'){//标签
            //          params[key]=enterpriseWhere[key].selfCode;
                     
            //     }else{
            //         params[key]=enterpriseWhere[key].code;
            //     }
            // }
            exportEpList(params).then(res => {
                _loading.close();
                console.log(res);
                if(res.code==20000)
                {
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href =res.data //'http://172.25.146.86/report/'+res.data
                    //link.download = '重点企业列表.xls'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(link)
                } 
            })
        }
 
        //获取企业列表
        getEnterpreiseListFun();
        onMounted(()=>{
         
        })
        return({
            labelList,
            enterpriseQuery,
            iptSearch,
            selCard,
            queryWhereFun,
            regCapitalList,
            estiblishTimeList,
            insuredPersonList,
            econoIndustryClass,
            regionList,
            enterpriseType,
            handleCloseFun,
            enterpriseList,
            currentPage,
            pageSize,
            total,
            handleCurrentChangeFun,
            download,
            parseTime,
        })
    },  
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.card {
    position: relative;
    box-sizing: content-box;
}
.card .card-c {
    margin-right: 70px;
    height: 70px;
    color: gold;
    .number-text{
        font-size: 18px;
    }
}
.card .card-p {
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(../..//assets/piebg.0d174014.png);
    padding: 5px;
    width: 60px;
    height: 60px;

}
.pie {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  list-style: none;
  overflow: hidden;
  transform: rotate(0deg) /*针对mac safari浏览器兼容*/
}
.slice {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.slice-1 {
  transform: rotate(0deg) skewY(0deg);
  background:rgba(63, 148, 253, .6);
}
.slice-2 {
  transform: rotate(90deg) skewY(0deg);
  background: rgba(63, 148, 253, .6);
}
.slice-3 {
  transform: rotate(180deg) skewY(0deg);
  background: rgba(63, 148, 253,.6);
}
.pie {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    list-style: none;
    transform: rotate(0deg);
}
 .result-tips {
    line-height: 40px;
    background-color: #fcfcfc;
    border-bottom: 1px solid $bordercolor;
    padding: 0 20px;
    .el-button{
        float: right;
        margin-top: 4px;
    }
 }
 .result-list li{
    min-height: 100px;
    padding:20px 10px;
    // margin: 20px 0px;
    border-bottom: 1px solid $bordercolor;
 }
 .result-list li:hover{
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 5px -3px rgb(0 0 0 / 6%), 0 4px 12px 4px rgb(0 0 0 / 6%);
 }

 .result-list li .name {
    display: block;
    color:$text-color;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.3s ease;
    margin-bottom: 10px;
}
.result-list-content{
    position: relative;
    padding:0 10px 0 130px;
    min-height:100px;
    border-color: transparent;
}
.tag-cus-label{
    margin:5px;
    color: #449de6;
    background-color: rgba(64, 158, 255,0.1);
}

.col .el-card{
    cursor: pointer;
}
.col .el-card:hover{
    border: 1px solid $themeColor;
}
.search-t{
    position:absolute;
    padding:6px;
    color:gray;
}
.search-content{
    padding-left: 80px;
    overflow: hidden;
    ul >li{
        float: left;
        padding:6px;
        cursor: pointer;
    }
    ul >li:hover{
       color: $specialtext-color;// $titcolor;
    }
    .el-input{
        width: 400px;
    }
}
.search-title{
    font-weight: 600;
    font-size: 14px;
    border-bottom:2px solid $themeColor;
    margin-bottom: 10px;
    span{
        background-color: $themeColor;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        letter-spacing:1px;
        color:#fff;
    }
}
.ep-link{
    font-size:18px;
    font-weight: bold;
    margin-bottom: 5px;
}
.page-cus {
    white-space: nowrap;
    padding: 20px;
    color: #303133;
    font-weight: bold;
    background: #fff;
    
}
.result-list-content .site {
    position: absolute;
    text-align: right;
    right: 24px;
    top: 2px;
    line-height: 20px;
    font-size: 16px;
}
</style>